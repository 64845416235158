class preparinginsurancepolicy {
  constructor() {
    this.items = {
      id: 30001,
      name: "Personal Info",
      type: "text",
      slideimg: "/doctorassistant/dia.jpg",
      // options: [
      //   {
      //     id: 40017,
      //     text: "Here you go... Save Drive",
      //     name: "Here you go... Save Drive",
      //     price: "",
      //     type: "hereyougo",
      //     desc: "Here you go... Save Drive",
      //     inputtype: "button",
      //   },
      // ],
      backtostart: true,
      text: [
        `<p>We are now preparing your digital car insurance policy.</p> `,
        // `<p>Link for the policy will be shown.</p> `,
        `<p>Soon you will get the policy. Drive safe.</p> `,
      ],
    };
  }

  getAllItems() {
    return this.items;
  }
}

export default preparinginsurancepolicy;
