class PocketInsurance {
  constructor() {
    this.items = [
      {
        id: 1239,
        image: "assets/MiniAppAssets/key.png",
        name: "Car Keys Insurance",
        badge: "Monthly Premium",
        coverage: "100,000",
        price: "200",
        bgColorClass: "bg-warning",
        description: `<p><strong>Key Features &amp; Benefits:</strong></p>
<ol>
<li>Key Replacement Coverage:</li>

<ul>
<li>Covers the cost of replacing damage, lost or stolen car keys.</li>
<li>Includes the reprogramming of smart keys or key fobs.</li>
</ul>

<li>Remote and Immobilizer Reprogramming:</li>
<ul>
<li>Covers expenses for reprogramming immobilizers or keyless entry systems after key theft. Within the insurance limit.</li>
</ul>
</ol>`,
        type: "insurance",
      },
      {
        id: 1240,
        image: "assets/MiniAppAssets/doc.png",
        name: "Car documents Insurance",
        badge: "Monthly Premium",
        coverage: "50,000",
        price: "100",
        bgColorClass: "bg-warning",
        description: `
          <p><strong>Key Features</strong></p>
<ol>
<li>Loss of Car Documents Covered</li>
<ul>
<li>Provides financial reimbursement for the expenses required to reissue lost car documents, including:</li>
</ul>
<ol>
<li>Registration Certificate (RC).</li>
<li>Smart Card</li>
</ol>
<li>Duplicate Document Expense Coverage</li>

<ul>
<li>Full or partial reimbursement for costs incurred in obtaining duplicate documents, including official fees and urgent processing charges.</li>
</ul>
</ol>
        `,
        type: "insurance",
      },
      {
        id: 1241,
        image: "assets/MiniAppAssets/passport.png",
        name: "Passport insurance",
        badge: "Monthly Premium",
        coverage: "50,000",
        price: "75",
        bgColorClass: "bg-warning",
        description: `
        <p><strong>Key Features:</strong></p>
<p><strong>Coverage:</strong></p>
<ul>
<li><strong>Loss of Passport:</strong><br /> Financial coverage for passports lost due to misplacement or theft within Pakistan.</li>
<li><strong>Damage to Passport:</strong><br /> Reimbursement of expenses incurred to replace a damaged passport that is no longer usable.</li>
<li><strong>Theft of Passport:</strong><br /> Covers the cost of replacing a stolen passport after filing an FIR.</li>
</ul>
<p><strong>Duplicate Passport Expenses:</strong></p>
<p>Covers all government fees and service charges required to reissue a duplicate passport, including:</p>
<ul>
<li>Passport reissuance fees at the passport office.</li>
</ul>
`,
        type: "insurance",
      },
      {
        id: 1242,
        image: "assets/MiniAppAssets/cnic.png",
        name: "CNIC Cover",
        badge: "Monthly Premium",
        coverage: "10,000",
        price: "20",
        bgColorClass: "bg-warning",
        description: `
         <p><strong>Key Features:</strong></p>
<ol>
<li>Coverage for Loss, or Theft of CNIC:</li>
<ul>
<li>Financial reimbursement for the reissuance of a lost or stolen CNIC.</li>
<li>Covers instances where the CNIC is misplaced, or stolen within Pakistan.</li>
</ul>
<li>Duplicate Document Expense Coverage:</li>

<ul>
<li>Full reimbursement of NADRA's charges for reissuing a duplicate CNIC, including urgent or executive processing fees, within the insurance limit.</li>
</ul>
</ol>
        `,
        type: "insurance",
      },
      {
        id: 1243,
        image: "assets/MiniAppAssets/enddoc.png",
        name: "Educational Documents",
        badge: "Monthly Premium",
        coverage: "50,000",
        price: "100",
        bgColorClass: "bg-warning",
        description: `
        <p><strong>Key Features:</strong></p>
<p><strong>Coverage:</strong></p>
<ul>
<li><strong>Loss of Documents:</strong> Covers expenses for the loss of essential educational documents such as:</li>
</ul>
<ul>
<li>Degrees (Bachelor&rsquo;s, Master&rsquo;s, etc.).</li>
<li>Certificates (Matriculation, Intermediate, O-Level, A-Level).</li>
<li>Diplomas and Professional Certifications (e.g., ACCA, PMP).</li>
</ul>
<ul>
<li><strong>Damage to Documents:</strong> Covers expenses for replacing documents damaged.</li>
<li><strong>Theft of Documents:</strong> Provides financial coverage for stolen documents, provided an FIR is filed.</li>
</ul>
<p><strong>Duplicate Document Expense:</strong></p>
<ul>
<li>Full reimbursement of expenses related to obtaining duplicate copies of lost or damaged documents. Within the insurance limit.</li>
<li>Includes processing fees, and courier charges.</li>
</ul>
        `,
        type: "insurance",
      },
      {
        id: 1244,
        image: "assets/MiniAppAssets/watch.png",
        name: "Smart watches",
        badge: "Monthly Premium",
        coverage: "100,000",
        price: "200",
        bgColorClass: "bg-warning",
        description: `
          <p><strong>Key Benefits by Product</strong></p>
<strong>Smart Watches Insurance</strong>:
<ul>
<li>Covers burglary of smartwatches, ensuring financial security for high-value items.</li>
<li>Quick claims processing for a stress-free experience.</li>
<li>Protection against accidental damage to the smartwatch.</li>
<li>Coverage for repair costs in case of partial damage.</li>
</ul>
</ol>
        `,
        type: "insurance",
      },
      {
        id: 1245,
        image: "assets/MiniAppAssets/license.png",
        name: "Home Registry",
        badge: "Monthly Premium",
        coverage: "100,000",
        price: "200",
        bgColorClass: "bg-warning",
        description: `
         <p><strong>Key Features:</strong></p>
<p><strong>Coverage for Loss or Theft of Home Registry:</strong></p>
<ul>
<li>Financial protection for the loss or theft of a home registry document within Pakistan.</li>
<li>Includes reissuance costs within the insurance limit.</li>
</ul>
<p><strong>Duplicate Document Expense Coverage:</strong></p>
<ul>
<li>Full reimbursement of government-imposed fees for obtaining duplicate documents, including processing and urgent reissuance charges within the insurance limit.</li>
</ul>
        `,
        type: "insurance",
      },
    ];

    this.terms = `
     <b style="font-size: 12px;">Disclaimer: </b><small style="font-size: 10px;"> Webdoc in partnership with our underwriter East West insurance company is providing the product. This is a recurring payment model.</small><br></br>
    <p>Pocket Size Insurance is a micro-insurance product offering affordable coverage for specific risks, including accidents and the loss of important documentation or personal items such as <strong>Car Keys, Transcripts, Degrees, Wearable Gadgets, Watches, Passports, CNIC</strong>. The product is designed to provide essential protection at a low premium with simple terms to enhance accessibility for a broad customer base.</p>
<p><strong>Key Features:</strong></p>
<ol>
<li><strong>Low Premium:</strong><br /> The insurance plan is highly affordable, with premiums starting from as low as Rs. 50 per month, ensuring that individuals across various income levels can benefit from this coverage.</li>
<li><strong>Basic Coverage:<br /></strong>The policy covers a variety of minor losses, including:</li>
<ul>
<li>Loss of Car Keys</li>
<li>Loss of Transcripts and Degrees</li>
<li>Burglary of Smart Watches</li>
<li>Loss of car documentation</li>
<li>Loss of Passports</li>
<li>Loss of CNICs</li>
<li>Loss of House Registry</li>
</ul>
<li><strong>Accessibility:<br /></strong>The entire insurance process, from purchase to claim management, is fully digitized. The product can be purchased and managed through a mini app, making it easy for policyholders to access insurance services anytime, anywhere.</li>
<li><strong>Hassle-Free Claims:<br /></strong>Claims under this insurance plan are processed with minimal paperwork, ensuring a quick, efficient, and stress-free experience for the insured. The claim process is designed to be user-friendly and fast, removing the traditional barriers often associated with insurance claims.</li>
</ol>
<p><strong>Premium and Coverage Details:<br /><br /></strong></p>
<ul>
<li>Premium Amount: Starting from Rs. 100 per month</li>
<li>All Insurance policies are on recuring based payment method and can be unsubscribed at any time by calling helpline or through miniapp.</li>
<li>Coverage Limit: Based on the item insured (to be determined by policy specifics for each category of loss)</li>
</ul>
<p><strong>Exclusions:</strong></p>
<p>The following are not covered under the Pocket Size Insurance plan:</p>
<ul>
<li>Pre-existing damages or loss before policy activation</li>
<li>Intentional damage or fraudulent claims</li>
<li>Loss due to gross negligence (e.g., leaving items unattended in public places)</li>
<li>Items not specifically listed in the insured category or under coverage limits</li>
</ul>
<p><strong>Claims Process:</strong></p>
<ol>
<li>Report the Loss:<br /> Policyholders can report any loss through the app/WhatsApp by submitting basic information and proof of the incident</li>
<li>Required Documents:<br /> Police report, receipts, or certifications ownership, CNIC</li>
<li>Claim Assessment:<br /> The insurance team will assess the claim within 48 hours of submission.</li>
<li>Claim Approval and Payout:<br /> Approved claims will be processed, and the payout will be made directly to the policyholder&rsquo;s account within 5 working days after completion of document.</li>
</ol>
<p><strong>ELIGIBILITY &amp; ENROLMENT:</strong></p>
<ul>
<li>Applicants are eligible to apply for this insurance Plan under the Policy if Applicants meet ALL the criteria set out below:</li>
<li>All Eligible Applicants shall be natural persons. Corporations, partnerships and businesses shall not be eligible for coverage under this Policy.</li>
<li>All Eligible Applicants shall be a minimum of eighteen (18) years of age and a maximum of fifty-nine (70) years of age at the time of registration.</li>
<li>A waiting period of 7 days applies.</li>
</ul>
<p><strong>MISTAKE IN AGE:</strong></p>
<ul>
<li>The Company shall only pay Insurance Benefits based on the disclosed age of the Insured. With the onus on the customer for incorrect disclosure the Company shall not be liable to pay any benefit under this Policy in that case.</li>
</ul>
<p><strong>INTENTIONAL FALSE STATEMENTS OF THE INSURED:</strong></p>
<ul>
<li>In the event of any concealment or misrepresentation the insurance Policy shall become null and void with respect to the relevant Insured.</li>
</ul>
<p><strong>NOTICE OF CLAIMS:</strong></p>
<p>The Company shall be notified of the occurrence of the Insured Claim as soon as possible, but not later than 15 (FIFTEEN days) from the date of loss, which shall be treated as time‑barred and the Company shall not be bound to pay the Claim.</p>
<p>Pricing for Products are variable and may be modified at any point in time with mutual consent. All price changes shall take effect immediately upon posting.</p>
<p>The insurance policy is set to automatically renew on a monthly basis for the next five (5) years. Policyholders can unsubscribe anytime.</p>
  `;
  }

  getAllItems() {
    return this.items;
  }

  getTerms() {
    return this.terms;
  }
}

export default PocketInsurance;
