class thirdpartytheft {
  constructor() {
    this.items = {
      id: 30001,
      name: "Third party / Fire & Theft",
      type: "text",
      slideimg: "/doctorassistant/dia.jpg",
      options: [
        {
          id: 40012,
          text: "Toyota",
          name: "Toyota",
          price: "",
          type: "vehiclemake",
          desc: "Toyota",
          inputtype: "button",
        },
        {
          id: 40012,
          text: "Honda",
          name: "Honda",
          price: "",
          type: "vehiclemake",
          desc: "Honda",
          inputtype: "button",
        },
        {
          id: 40011,
          text: "Suzuki",
          name: "Suzuki",
          price: "",
          type: "vehiclemake",
          desc: "Suzuki",
          inputtype: "button",
        },
      ],
      backtostart: false,
      text: [
        `<p>This type of car insurance provides coverage for damages or losses caused to a third party as a result of an accident involving the insured vehicle. Additionally, it includes coverage for loss or damage to the insured vehicle due to fire or theft.</p> `,
        `<p>Perfect. You love your vehicle. It must be very costly. Let’s get some more information. Please select the make of your vehicle</p> `,
      ],
    };
  }

  getAllItems() {
    return this.items;
  }
}

export default thirdpartytheft;
