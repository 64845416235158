class customvehiclemake {
    constructor(msg) {
        this.items =
        {
            id: 30001,
            name: "Custom Vehicle Make",
            type: "text",
            slideimg: "/doctorassistant/dia.jpg",
            options: [
                { id: 40012, text: "Submit", name: "Submit", type: "vehiclemodel", desc: msg.desc, inputtype: "textinput" },
            ],
            backtostart: false,
            text: [
                `<p>Please enter the make of your vehicle.</p> `
            ]
        }
    }

    getAllItems() {
        return this.items;
    }
}

export default customvehiclemake;
