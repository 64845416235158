import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import PocketPackage from "../components/PocketPackage";
import getInsuranceData from "../modals/getInsuranceData";
import Header_packages from "../components/Header_packages";
import PocketSizeActivatedPackageComponent from "../components/PocketSizeActivatedPackages";
import Header from "../components/Header";
import { useLoading } from "../contexts/LoadingContext";

const PocketSize = () => {
  const [userData, setUserData] = useState(null);
  const [details, setDetails] = useState(null);
  const { isLoading, setIsLoading } = useLoading();
  const type = "pocket";
  const service = "pocketsize_insurance";
  const id = "1036";
  const [customerNumber, setCustomerNumber] = useState(null);

  // Function to handle user authentication code result
  const getUserAuthCodeResult = (jsonResult) => {
    const result = JSON.parse(jsonResult);
    try {
      if (result.status === "SUCCESS") {
        const form = new FormData();
        form.append("value", result.authCode);

        axios
          .post(
            "https://easypaiseminiapp.webddocsystems.com/public/api/v1/auth",
            form,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            if (response.data.openId) {
              sessionStorage.setItem("user", JSON.stringify(response.data));
              setUserData(response.data); // Set the user data
              setCustomerNumber(response.data.userMsisdn);
            } else {
              Swal.fire({
                icon: "info",
                allowOutsideClick: false,
                title: "Something went wrong. Please try again later!",
                confirmButtonText: "Retry",
              }).then(() => {
                authenticateUser();
              });
            }
          })
          .catch((error) => {
            console.error(error);
            setIsLoading(false); // Stop loading on error
          });
      } else {
        Swal.fire({
          allowOutsideClick: false,
          title: "Please Share the information for Services",
          confirmButtonText: "Retry",
        }).then((result) => {
          if (result.isConfirmed) {
            authenticateUser();
          } else {
            setIsLoading(false);
          }
        });
      }
    } catch (error) {
      console.error("Error processing auth code:", error);
      setIsLoading(false);
    }
  };

  // Function to authenticate the user
  const authenticateUser = () => {
    setIsLoading(true);
    const scopes = [
      "USER_MSISDN",
      "USER_NAME",
      "OPEN_ID",
      "UNION_ID",
      "USER_BIRTHDAY",
    ];
    window.mp.getUserAuthCode(scopes);
    window.getUserAuthCodeResult = getUserAuthCodeResult;
  };

  // Fetch subscription status
  const fetchSubscriptionStatus = async (customerNumber, service, id) => {
    setIsLoading(true); // Set loading to true when fetching

    try {
      const response = await fetch(
        `https://insuranceservice.webddocsystems.com/api/MiniApp/PocketSizeCheckCategoryPackages?id=${id}&customerNumber=${customerNumber}&service=${service}`,
        {
          method: "GET",
          headers: {
            Authorization:
              "Basic ZlJqZGZVbWFzOmFmMzNhZmNhODAwNzZkOWMxMTViOGU0NjAyZjc4YTIx",
          },
        }
      );
      const data = await response.json();
      setDetails(data); // Save subscription details in state
    } catch (error) {
      console.error("Error fetching subscription status:", error);
    } finally {
      setIsLoading(false); // Set loading to false once the API call is complete
    }
  };

  // Use effect to start the authentication process
  useEffect(() => {
    setIsLoading(true);
    const sessionUser = JSON.parse(sessionStorage.getItem("user"));
    if (sessionUser) {
      setIsLoading(false);
      setUserData(sessionUser);
      setCustomerNumber(sessionUser.userMsisdn);
    } else {
      authenticateUser();
    }
  }, []);

  // Use effect to fetch subscription status after user is authenticated
  useEffect(() => {
    if (userData && customerNumber && service && id) {
      fetchSubscriptionStatus(customerNumber, service, id); // Fetch subscription status after user is authenticated
    }
  }, [userData]);

  const { items, terms } = getInsuranceData(type);

  // Check if details and details.packages are defined before calling .some()
  const filteredItems = items.filter((item) => {
    // Check if the item is not subscribed to (i.e., the pid is not in the packages list)
    return !details?.data?.some((pkg) => pkg.pid === item.id);
  });

  if (isLoading) {
    return <div></div>; // Add a loading state
  }

  return (
    <>
      <div>
        <Header_packages title="Pocket Size Insurance" />
        <Header
          userSession={JSON.parse(sessionStorage.getItem("user"))}
          packageData="notActive"
        />
        <div>
          {details && details.data ? (
            <PocketSizeActivatedPackageComponent items={details.data} />
          ) : (
            <p></p>
          )}
        </div>
        <PocketPackage
          items={Array.isArray(filteredItems) ? filteredItems : []}
          terms={terms}
        />
      </div>
    </>
  );
};

export default PocketSize;
