class vehicleprice {
    constructor() {
        this.items = {
            id: 30001,
            name: "Vehicle Price",
            type: "text",
            slideimg: "/doctorassistant/dia.jpg",
            options: [
                { id: 40012, text: "Enter Price", name: "Enter Price", price: "", type: "confirmprice", desc: "Enter Price", inputtype: "textinput" },
            ],
            backtostart: false,
            text: [
                `<p>Please enter the price of your vehicle.</p> `
            ]
        };
    }

    getAllItems() {
        return this.items;
    }
}

export default vehicleprice;
