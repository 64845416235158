// src/modals/getInsuranceData.js

import accidental_insurance from "./accidental_insurance";
import bike_packages from "./Bike_packages";
import car_packages from "./Car_packages";
import health_insurance from "./health_insurance";
import PocketInsurance from "./pocket_insurance";
import income_protection from "./income_protection";
import women_insurance from "./women_healthcard";
import calladoc_packages from "./calladoc_packages";
import agri_experts from "./Agri_experts";
import vets from "./vets";
import live_stock from "./live_stock";
import crop_insurance from "./crop_insurance";
import mobile_insurance from "./mobile_insurance";
import monthly_mobile_insurance from "./monthly_mobile_insurance";

const getInsuranceData = (type) => {
  let insuranceData;
  switch (type) {
    case "car":
      insuranceData = new car_packages();
      break;
    case "bike":
      insuranceData = new bike_packages();
      break;
    case "accidental":
      insuranceData = new accidental_insurance();
      break;
    case "health":
      insuranceData = new health_insurance();
      break;
    case "income":
      insuranceData = new income_protection();
      break;
    case "mobile":
      insuranceData = new mobile_insurance();
      break;
    case "monthmobile":
      insuranceData = new monthly_mobile_insurance();
      break;
    case "women":
      insuranceData = new women_insurance();
      break;
    case "calladoc":
      insuranceData = new calladoc_packages();
      break;
    case "agri":
      insuranceData = new agri_experts();
      break;
    case "vets":
      insuranceData = new vets();
      break;
    case "livestock":
      insuranceData = new live_stock();
      break;
    case "crops":
      insuranceData = new crop_insurance();
      break;
    case "pocket":
      insuranceData = new PocketInsurance();
      break;
    default:
      return { items: [], terms: "" };
  }

  return {
    items: insuranceData.getAllItems(),
    terms: insuranceData.getTerms(),
  };
};

export default getInsuranceData;