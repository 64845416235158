import React, { useState, useEffect, useRef } from "react";
import { Col, Container, Row, Button, Form } from "react-bootstrap";
import TextInputOption from './TextInputOption';

const HandleOptions = ({ messages, handleOptionSelect, handleTextSubmit }) => {
  const [loading, setLoading] = useState(false);
  console.log(messages);

  const optionMessages = messages.filter(msg => msg.isOption);

  if (!optionMessages || optionMessages.length === 0) {
    return null;
  }

  return (
    <>
      <div className="row">
        {optionMessages.map((msg, index) => (
          <React.Fragment key={index}>
            {msg.inputtype === "button" ? (
              <Button key={index} variant="secondary"
                                        style={{ backgroundColor: "#1bb367" }}
                                        className="border-0 w-75 mb-2 chatOptions"
                                        onClick={() => handleOptionSelect(msg)}>
                {msg.text}
              </Button>
            ) : msg.inputtype === "textinput" ? (
              <TextInputOption key={index} msg={msg} handleTextSubmit={handleTextSubmit} />
            ) : msg.price && msg.price ? (
              <div className="col-6" onClick={() => handleOptionSelect(msg)}>
                <div className="card border border-success">
                  <div className="card-body text-center">
                    {/* Package Name */}
                    <h6>{msg.name}</h6>

                    {/* Price */}
                    <h6 className="mt-2 mb-2">
                      <strong style={{ fontSize: "15px" }}>
                        Rs {msg.price}
                      </strong>
                    </h6>

                    {/* Description */}
                    <small
                      className="text-muted text-start"
                      dangerouslySetInnerHTML={{ __html: msg.desc }}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-12">
                <div className="p-0">
                  {/* Dynamically render button or input */}
                  {msg.inputtype === "text" ? (
                    <Form.Control
                      type="text"
                      placeholder="Enter your text here"
                      className="w-75 mb-2"
                      onBlur={() => handleOptionSelect(msg)} // Handle when user finishes input
                    />
                  ) : (
                    <Button
                      variant="secondary"
                      style={{ backgroundColor: "#1bb367" }}
                      className="border-0 w-75 mb-2 chatOptions"
                      onClick={() => handleOptionSelect(msg)}
                    >
                      <p className="mb-0">{msg.name}</p>
                    </Button>
                  )}
                </div>
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    </>
  );
};

export default HandleOptions;
