import React, { useState, useEffect, useCallback } from "react";
import ChatVideoCall from "../pages/chat-video-call";
import { useNavigate } from "react-router-dom";
import { Row, Col, Card, Button, Modal } from "react-bootstrap";
import { onValue, ref } from "firebase/database";
import { database } from "../firebase/firebaseConfig";
import "./doctor-list-component.css";

const ChatDoctorList = ({messages,setMessages,setShowList}) => {
  const [doctors, setDoctors] = useState([]);
  const [showModal, setShowModal] = useState(false); // For the first modal (Call Now)
  const [showVideoModal, setShowVideoModal] = useState(false); // For the second modal (Video Call)
  const [selectedDoctorEmail, setSelectedDoctorEmail] = useState(null);
  const [selectedDoctorId, setSelectedDoctorId] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://testingnew.webddocsystems.com/public/api/v1/doctor/list?page=1&perPage=30`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();

        if (data.payLoad === null) {
          console.log("No data found");
        } else if (Array.isArray(data.payLoad)) {
          setDoctors((prevDoctors) => [...prevDoctors, ...data.payLoad]);
        } else {
          const payloadArray = Object.values(data).find(Array.isArray);
          if (payloadArray) {
            setDoctors((prevDoctors) => [...prevDoctors, ...payloadArray]);
          } else {
            throw new Error("Payload data is not an array");
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const realtimeRef = ref(database, "Doctors");
    const unsubscribe = onValue(realtimeRef, (snapshot) => {
      const realtimeData = snapshot.val();
      if (realtimeData) {
        const updatedDoctors = doctors.map((doctor) => {
          const email = doctor.Email.replace(/\./g, "");
          const status = realtimeData[email]?.status || doctor.status;
          return { ...doctor, status };
        });
        setDoctors(updatedDoctors);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [doctors]);

  const onlineDoctors = doctors.filter((doctor) => doctor.status === "online");
  const busyDoctors = doctors.filter((doctor) => doctor.status === "busy");
  const offlineDoctors = doctors.filter(
    (doctor) => doctor.status === "offline"
  );

  const doctorListToRender = onlineDoctors.concat(busyDoctors, offlineDoctors);

  const renderDoctorCards = (doctorListToRender) => {
    return doctorListToRender.slice(0, 3).map((doctor) => (
      <Col xs={4} key={doctor.id}>
        <Card>
          <div style={{ height: "100px", overflow: "hidden" }}>
            <Card.Img
              variant="top"
              src={doctor.ImgLink}
              style={{
                width: "100%",
                height: "157px",
                flex: "1",
                border: "none",
                position: "relative",
                maxWidth: "100%",
                overflow: "hidden",
                objectFit: "cover",
              }}
            />
          </div>
          <Card.Body
            style={{
              padding: "8px",
              lineHeight: "0px",
            }}
          >
            <p style={{ fontSize: "9px", color: "green" }}>
              {doctor.status.toUpperCase()}
            </p>
            <p style={{ fontSize: "9px", paddingTop: "2px" }}>
              {doctor.FirstName.charAt(0).toUpperCase() +
                doctor.FirstName.slice(1)}
            </p>

            <p style={{ fontSize: "7px" }}>{doctor.DoctorSpecialties}</p>
            <Button
              variant="outline-info"
              style={{
                fontFamily: "var(--font-poppins)",
                fontSize: "8px",
                fontWeight: "500",
                color: "var(--color-cadetblue-200)",
                borderRadius: "40px",
                border: 0,
                boxShadow: "0 0 6.3px 1px rgba(0, 0, 0, 0.25)",
              }}
              onClick={() => handleOpenModal(doctor.ApplicationUserId, doctor.Email,doctor.FirstName)}
              type="submit"
            >
              Call Now
            </Button>
          </Card.Body>
        </Card>
      </Col>
    ));
  };

  const handleOpenModal = (id, email,name) => {
    name='I’d like to consult '+name;
    setSelectedDoctorEmail(email);
    setSelectedDoctorId(id);
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        sender: "You",
        text: name
        
      },
    ]);
    // setShowList(false);
    setTimeout(() => {
      setShowVideoModal(true)
    },2000); 
    // Open the first modal (Call Now)
  };



  const handleOpenVideoModal = () => {
    setShowModal(false); // Close the first modal
    setShowVideoModal(true); // Open the second modal (Video Call)
  };

  const handleCloseVideoModal = () => {
    setShowVideoModal(false); // Close the second modal
    setSelectedDoctorEmail(null);
    setSelectedDoctorId(null);
  };

  const onAudioVideoCallClick = useCallback(() => {
    if (selectedDoctorEmail && selectedDoctorId) {
      console.log("Initiating call with", selectedDoctorEmail, selectedDoctorId);
      handleOpenModal(); // Open video modal after starting the call
    } else {
      console.error("Doctor profile data is not available.");
    }
  }, [selectedDoctorEmail, selectedDoctorId]);

  return (
    <div>
      <Row xs={1} md={2} lg={2} className="gx-3 mt-2">
        {renderDoctorCards(doctorListToRender)}
      </Row>

      {/* First Modal for Call Now */}
     

      {/* Second Modal for Video Call */}
      <Modal show={showVideoModal} onHide={handleCloseVideoModal} fullscreen>
        <Modal.Header closeButton>
          <Modal.Title>Video Call</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Render the ChatVideoCall component inside the modal */}
          {selectedDoctorEmail && <ChatVideoCall 
            email={selectedDoctorEmail} 
            showVideoModal={showVideoModal}
            setShowVideoModal={setShowVideoModal} 
            messages={messages}
            setMessages={setMessages}
            setShowList={setShowList}
        />}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ChatDoctorList;
