class promotionsstep2 {
  constructor(data) {
    const price = data.price;
    this.items = {
      id: 30001,
      name: "Promotions Step 2",
      type: "text",
      slideimg: "/doctorassistant/dia.jpg",
      options: [
        {
          id: 40012,
          text: "Proceed",
          name: "Proceed",
          price: price,
          type: "paymentoptions",
          desc: "Proceed",
          inputtype: "button",
        },
      ],
      backtostart: false,
      text: [
        `<p>Your lucky day. Easypaisa is offering 2% discount and a chance to win Rs. 10,000 cash in a lucky draw.</p>`,
      ],
    };
  }

  getAllItems() {
    return this.items;
  }
}

export default promotionsstep2;
