import { toWords } from 'number-to-words';

class gapinsurance {
    constructor(data) {
        const price = data.price;
        const marketValue = parseFloat(price);
        const gapInsuranceCost = (marketValue * 0.015);
        const formattedInsuranceCost = gapInsuranceCost.toLocaleString("en-US");
        const insuranceCostInWords = toWords(gapInsuranceCost);

        this.items = {
            id: 30001,
            name: "GAP Insurance",
            type: "text",
            slideimg: "/doctorassistant/dia.jpg",
            options: [
                {
                    id: 40012,
                    text: "Buy Now",
                    name: "Buy Now",
                    price: price,
                    type: "paymentoptions",
                    desc: "Buy Now",
                    inputtype: "button",
                },
                {
                    id: 40012,
                    text: "Find me a better price",
                    name: "Find me a better price",
                    price: "",
                    type: "promotions",
                    desc: "Find me a better price",
                    inputtype: "button",
                },
            ],
            backtostart: false,
            text: [
                `<p>The cost of insurance is calculated as Rs. ${formattedInsuranceCost} (${insuranceCostInWords}). Would you like to proceed?</p>`,
            ],
        };
    }

    getAllItems() {
        return this.items;
    }
}

export default gapinsurance;
