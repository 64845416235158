// src/components/Header.js
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa6";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { IoMdRefresh } from "react-icons/io";

const Header_packages_chat = ({ title }) => {

const sessioncheck= sessionStorage.getItem("user");
  if(!sessioncheck)
  {
    const user = {
      openId: "5da34d83de18ba92f44040c10b406c8ad4bb1375",
      unionId: "5ba759f37b91d2a51f20c014a7c3ba2a504055c7",
      userMsisdn: "0345000014",
      userName: "Khumash",
      applicationUserId: "27C62D9C-AB40-450C-A6CE-7851F47190FA",
      // applicationgitUserId: "2F02828E-B0E0-4570-9815-CDFBFB7D6DD0",
      userEmail: "03145362496@webdoc.com.pk",
      // applicationUserId: "B11000A1-70CA-4E67-A2A1-11DF65FEE0D9",
      package: "",
      message: "User registered and authenticated successfully  .",
    };

    sessionStorage.setItem("user", JSON.stringify(user));
    console.log(sessionStorage.getItem("user"));
  }


  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleRefresh = () => {
    //startagainFunc("start");
  }

  
  // List of paths where the back button should not appear
  const noBackButtonPaths = [
    '/health',
    '/income',
    '/accidental',
    '/car',
    '/bike',
    '/crop',
    '/womenHealth',
    '/liveStock',
    '/mobile',
  ];

  return (
    <div className="container-fluid" style={{ backgroundColor: "#1bb367" }}>
      <div className="container" >
        <div className="row py-2 align-tems-center" style={{marginBottom: "-1px"}}>
          {/* {
          !noBackButtonPaths.includes(location.pathname) && (
            <div className="col-2 d-flex justify-content-start align-items-center">
              <button className="btn btn-link" onClick={handleBackClick}>
                <FaArrowLeft size={15} />
              </button>
            </div>
          )
        } */}
          <div className="col-3">
            <button className="btn btn-link text-white ps-0" onClick={handleBackClick}>
              <FaChevronLeft size={15} />
            </button>
          </div>

          <div className="col-6 text-center  bg-white rounded-pill fw-normal py-1">
            <img className="mb-0 custom-heading w-50" src="/assets/MiniAppAssets/logo.png" ></img>
            {/* <h6 className="mb-0 custom-heading bg-white rounded-pill fw-normal py-1" dangerouslySetInnerHTML={{ __html: title }}></h6> */}
          </div>
          <div className="col-3 my-auto text-end">
            <Link onClick={handleShow} className="text-white text-decoration-none">Help</Link>
            
          </div>
          {/* <div className="col-1 my-auto text-end" onClick={handleRefresh}>
         
            <IoMdRefresh  className="mt-2"/>
            
          </div> */}
         
        </div>
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className="mb-0">Help</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-1">
          <div className="card p-2 text-white text-center" style={{ backgroundColor: "#1bb367" }}>
            Call Now for Help
            <a href="tel:03443333737" className="d-block fs-4 fw-bold mt-0 text-decoration-none text-white mb-2">0344 3333737</a>
            Or email us at
            <a href="mailto:support@webdoc.com" className="d-block fs-4 fw-bold mt-0 text-decoration-none text-white">support@webdoc.com</a>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Header_packages_chat;