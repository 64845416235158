class promotionsstep1 {
    constructor(data) {
        const price = data.price;
        this.items = {
            id: 30001,
            name: "Promotions Step 1",
            type: "text",
            slideimg: "/doctorassistant/dia.jpg",
            options: [],
            backtostart: false,
            text: [
                `<p>Let me search if there are any promotions running on Car insurance.</p>`
            ]
        };
    }

    getAllItems() {
        return this.items;
    }
}

export default promotionsstep1;
