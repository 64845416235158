class start {
  constructor() {
    const sessionUser = JSON.parse(sessionStorage.getItem("user"));
    this.items = {
      id: 30001,
      name: "Start Text",
      type: "text",
      slideimg: "/doctorassistant/dia.jpg",
      options: [
        {
          id: 40012,
          text: "Comprehensive Car Insurance",
          name: "Comprehensive Car Insurance",
          price: "",
          type: "comprehensive",
          desc: "I’d like to get the Comprehensive Car Insurance",
        },
        {
          id: 40012,
          text: "Third party / Fire & Theft",
          name: "Third party / Fire & Theft",
          price: "",
          type: "thirdpartytheft",
          desc: "I’d like to get the Third party / Fire & Theft",
        },
        {
          id: 40011,
          text: "Third Party Only",
          name: "Third Party Only",
          price: "",
          type: "thirdparty",
          desc: "I’d like to get the Third Party Only",
        },
      ],
      backtostart: false,
      text: [
        `<p>Hello, ${sessionUser.userName}! 👋 Welcome to <strong>WEB<span style="color: #ff0000;">DOC</span></strong>. How can I assist you today?</p> `,
      ],
    };
  }

  getAllItems() {
    return this.items;
  }
}

export default start;
