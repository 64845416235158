class allowcamera {
  constructor() {
    this.items = {
      id: 30001,
      name: "Personal Info",
      type: "text",
      slideimg: "/doctorassistant/dia.jpg",
      options: [
        {
          id: 40017,
          text: "Open Camera",
          name: "Open Camera",
          price: "",
          type: "opencamera",
          desc: "Open Camera",
          inputtype: "button",
        },
      ],
      backtostart: false,
      text: [
        `<p>We need your camera permissions.</p> `,
      ],
    };
  }

  getAllItems() {
    return this.items;
  }
}

export default allowcamera;
