class feedbackBad {
    constructor() {
      this.items = 
        {
          id: 50001,
          name: "About",
          type: "text",
          slideimg: "/doctorassistant/natasha.jpg",
          options: [
            { id: 71100, text: "Un-satisfactory Doctors’ behaviour", name: "Un-satisfactory",  type: "ComplaintEnd", desc: "Un-satisfactory Doctors’ behaviour" },
            { id: 71101, text: "Poor Video or Voice quality", name: "Poor Video or Voice quality",  type: "ComplaintEnd", desc: "Poor Video or Voice quality" },
            { id: 71102, text: "Technical issue", name: "Technical issue",  type: "ComplaintEnd", desc: "Technical issue" },
          ],
          backtostart:true,
          text: `I am sorry for your experience. We are doing our best to improve our services. You can make a complaint, and my manager will investigate this and revert. `,
        }
    }

    getAllItems() {
      return this.items;
    }

  }
  
  export default feedbackBad;
  