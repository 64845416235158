class vehiclemake {
   
    constructor(msg) {
        this.items =
        {
            id: 30001,
            name: "Vehicle Make",
            type: "text",
            slideimg: "/doctorassistant/dia.jpg",
            options: [
                ],
            backtostart: false,
            text: [
                `<p>Great! Now, please select the model of your ${msg.desc} vehicle.</p> `
            ]
        }

        this.paramMake = msg.desc;
        this.make = {
            Toyota: [
                { id: 40012, text: "Corolla", name: "Corolla", type: "vehiclemodel", desc: "Corolla", inputtype: "button" },
                { id: 40012, text: "Prius", name: "Prius", type: "vehiclemodel", desc: "Prius", inputtype: "button" },
                { id: 40011, text: "Vitz", name: "Vitz", type: "vehiclemodel", desc: "Vitz", inputtype: "button" },
                { id: 40012, text: "Prado", name: "Prado", type: "vehiclemodel", desc: "Prado", inputtype: "button" },
                { id: 40012, text: "Landcruiser", name: "Landcruiser", type: "vehiclemodel", desc: "Landcruiser", inputtype: "button" },
                { id: 40011, text: "Revo", name: "Revo", type: "vehiclemodel", desc: "Revo", inputtype: "button" },
                { id: 40012, text: "Rocco", name: "Rocco", type: "vehiclemodel", desc: "Rocco", inputtype: "button" },
                { id: 40012, text: "Cross", name: "Cross", type: "vehiclemodel", desc: "Cross", inputtype: "button" },
                { id: 40011, text: "Camry", name: "Camry", type: "vehiclemodel", desc: "Camry", inputtype: "button" },
                { id: 40011, text: "Fortuner", name: "Fortuner", type: "vehiclemodel", desc: "Fortuner", inputtype: "button" },
                { id: 40013, text: "Other", name: "Other", type: "vehiclemodel", desc: "Other", inputtype: "textinput" }],
            Honda: [
                { id: 40012, text: "Civic", name: "Civic", type: "vehiclemodel", desc: "Civic", inputtype: "button" },
                { id: 40012, text: "City", name: "City", type: "vehiclemodel", desc: "City", inputtype: "button" },
                { id: 40012, text: "Accord", name: "Accord", type: "vehiclemodel", desc: "Accord", inputtype: "button" },
                { id: 40012, text: "BRV", name: "BRV", type: "vehiclemodel", desc: "BRV", inputtype: "button" },
                { id: 40013, text: "Other", name: "Other", type: "vehiclemodel", desc: "Other", inputtype: "textinput" }],
            Suzuki: [
                { id: 40012, text: "Alto", name: "Alto", type: "vehiclemodel", desc: "Alto", inputtype: "button" },
                { id: 40012, text: "Mehran", name: "Mehran", type: "vehiclemodel", desc: "Mehran", inputtype: "button" },
                { id: 40011, text: "Jimmy", name: "Jimmy", type: "vehiclemodel", desc: "Jimmy", inputtype: "button" },
                { id: 40011, text: "Cultus", name: "Cultus", type: "vehiclemodel", desc: "Cultus", inputtype: "button" },
                { id: 40011, text: "WagonR", name: "WagonR", type: "vehiclemodel", desc: "WagonR", inputtype: "button" },
                { id: 40011, text: "Swift", name: "Swift", type: "vehiclemodel", desc: "Swift", inputtype: "button" },
                { id: 40011, text: "Bolan", name: "Bolan", type: "vehiclemodel", desc: "Bolan", inputtype: "button" },
                { id: 40011, text: "Ravi", name: "Ravi", type: "vehiclemodel", desc: "Ravi", inputtype: "button" },
                { id: 40013, text: "Other", name: "Other", type: "vehiclemodel", desc: "Other", inputtype: "textinput" }],
            Other: [
                { id: 40013, text: "Other", name: "Other", type: "vehiclemodel", desc: "Other", inputtype: "textinput" }],
            
        }
    }

    getAllItems() {
        
        this.items.options = this.make[this.paramMake];
        return this.items;
    }
}

export default vehiclemake;
