
class needto_see_doc {
    constructor() {
        this.items = {
            id: 30003,
            name: "calladoc", slideimg: "/doctorassistant/dia.jpg",
            type: "text",
            options: [],
            backtostart: true,
            text: ``,
        };
    }

    // Method to make the API call, now accepting customerNumber as a parameter
    async getAllItems() {
        let customerNumber = '0345000014';
        // Check if customerNumber is provided
        if (!customerNumber) {
            console.error("Customer number is required.");
            return;
        }

        const sessionUser = JSON.parse(sessionStorage.getItem("user"));
        if (sessionUser.package) {
            this.items.text = "Your package is activated we are transfering your data to Doctor Assitant. please wait ";
            // If responseCode is '0000', mark the options as activated
            return this.items;

        } else {
            this.items.options = [
                { id: 70, text: "1 month package", name: "1 Month package", price: "150", type: "payment", desc: "<p>Unlock the ultimate convenience with unlimited audio and video consultations, giving you direct access to expert care &ndash; tailored to fit your busy lifestyle!</p>" },
                { id: 72, text: "12 month package", name: "12 Month package", price: "999", type: "payment", desc: "<p>Enjoy unlimited audio and video consultations for your entire family, providing you with expert care, all from the comfort of home!</p>" }
            ];
            this.items.text = "Absolutely! To proceed, please select one of the following healthcare packages that best suits your needs:";
            return this.items;
        }

        // // Prepare the API URL and headers
        // const url = `https://testInsuranceservices.webddocsystems.com/api/MiniApp/CheckCategoryPackages?id=1027&customerNumber=${customerNumber}&service=health`;
        // const headers = {
        //     'Authorization': 'Basic ZlJqZGZVbWFzOmFmMzNhZmNhODAwNzZkOWMxMTViOGU0NjAyZjc4YTIx'
        // };

        // try {
        //     const response =  await fetch(url, { method: 'GET', headers: headers });

        //     const data = await response.json(); // Assuming the response is JSON

        //     if (data.responseCode === '0000') {
        //         this.items.text="Your package is activated we are transfering your data to Doctor Assitant. please wait ";
        //         // If responseCode is '0000', mark the options as activated
        //         return this.items;
        //     } else {
        //         // If responseCode is not '0000', mark the options as not activated
        //         this.items.options = [
        //             { id: 40001, text:"Please wait we are activating your package", name: "1 month package", price: "150", type: "payment", desc: "only for EasyPaisa Account Holder Individual. Access to a GP, 5 Video, 10 Audio " },
        //             { id: 40002, text:"Please wait we are activating your package", name: "3 month package", price: "250", type: "payment", desc: "for 5 family Members of EasyPaisa Account Holder Individual. Access to a GP, 10 Video, 15 Audio " }
        //         ];
        //         this.items.text="Please select Package from the following";
        //         return this.items;
        //     }
        // } catch (error) {
        //     console.error("Error making API call:", error);
        //     return this.items;
        //     // Handle error case (e.g., show error message, etc.)
        // }
    }

    // getAllItems() {
    //     return this.items;
    // }
}

export default needto_see_doc;
