// import React, { useState, useEffect, useRef } from 'react';
// import getDataCarInsurance from "../../modals/carinsurance/getcarinsurancemodel";

// const CameraComponent = ({ messages, setMessages, setShowCamera, setLoading }) => {
//   const [hasCameraPermission, setHasCameraPermission] = useState(false);
//   const [error, setError] = useState('');
//   const [imageCaptured, setImageCaptured] = useState(false); // State to track if the image is captured
//   const [capturedImage, setCapturedImage] = useState(null); // Store the captured image URL
//   const videoRef = useRef(null);
//   const canvasRef = useRef(null);
//   const streamRef = useRef(null); // To store the camera stream

//   useEffect(() => {
//     // Function to start the camera stream
//     const startCamera = async () => {
//       try {
//         console.log("Requesting camera access...");
//         const stream = await navigator.mediaDevices.getUserMedia({ video: true });
//         console.log("Camera stream initialized successfully.");
//         streamRef.current = stream;  // Store the stream reference
//         if (videoRef.current) {
//           videoRef.current.srcObject = stream;
//         }
//         setHasCameraPermission(true);
//       } catch (err) {
//         console.error("Error accessing the camera:", err);
//         setError('Failed to access the camera.');
//         setHasCameraPermission(false);
//       }
//     };

//     startCamera();

//     // Cleanup the camera stream when the component unmounts
//     return () => {
//       if (streamRef.current) {
//         const tracks = streamRef.current.getTracks();
//         tracks.forEach(track => track.stop()); // Stop all tracks of the stream
//       }
//     };
//   }, []);

//   // Capture image from the video stream and draw it on the canvas
//   const captureImage = async() => {
//     const video = videoRef.current;
//     const canvas = canvasRef.current;

//     // Ensure canvas size matches video dimensions
//     canvas.width = video.videoWidth;
//     canvas.height = video.videoHeight;

//     const context = canvas.getContext('2d');
//     context.drawImage(video, 0, 0, canvas.width, canvas.height);

//     // Convert the canvas to an image URL (data URL)
//     const imageURL = canvas.toDataURL('image/png');
//     setCapturedImage(imageURL);
//     setImageCaptured(true);

//     let newMessage = await getDataCarInsurance("preparinginsurancepolicy");
//     console.log(newMessage);
    
//     setMessages((prevMessages) => [...prevMessages, newMessage.modal]);

//     if (newMessage.modal.options && newMessage.modal.options.length > 0) {
//       newMessage.modal.options.forEach((option) => {
//         option.isOption = true;
//         option.sender = "Receiver";
//         setMessages((prevMessages) => [...prevMessages, option]);
//       });
//     }

//     // Stop the camera after capturing the image and hide the video element
//     if (streamRef.current) {
//       const tracks = streamRef.current.getTracks();
//       tracks.forEach(track => track.stop()); // Stop all tracks of the stream
//     }
//     setShowCamera(false); // Hide the camera interface
//   };

//   // Check video element state
//   const handleCanPlay = () => {
//     console.log("Video feed is ready to play.");
//   };

//   const handlePlayError = () => {
//     console.error("Video element failed to play.");
//   };

//   return (
//     <div>
//       {error && <p>{error}</p>}

//       {imageCaptured ? (
//         <div>
//           <img src={capturedImage} alt="Captured" style={{ width: '100%', height: 'auto' }} />
//           <br />
//           {/* No retake button */}
//         </div>
//       ) : (
//         <div>
//           {hasCameraPermission ? (
//             <>
//               <video
//                 ref={videoRef}
//                 autoPlay
//                 playsInline
//                 style={{ backgroundColor: '#000', border: '1px solid #ccc', width:"100%", height:"80vh" }} // Debug style
//                 onCanPlay={handleCanPlay} // Ensures the video is ready before we start playback
//                 onError={handlePlayError} // Handle error if video fails to load
//               />
//               <br />
//               <button onClick={captureImage}>Capture</button>
//             </>
//           ) : (
//             <p>Waiting for camera permission...</p>
//           )}
//         </div>
//       )}

//       {/* Canvas is hidden but used to capture the image */}
//       <canvas ref={canvasRef} style={{ display: 'none' }} />
//     </div>
//   );
// };

// export default CameraComponent;



import React, { useEffect, useRef, useState } from 'react';
import getDataCarInsurance from "../../modals/carinsurance/getcarinsurancemodel";
const CameraComponent = ({ messages, setMessages, setShowCamera, setLoading }) => {
  const videoRef = useRef(null);  // Ref for the video element
  const canvasRef = useRef(null);  // Ref for the hidden canvas
  const streamRef = useRef(null);  // Ref to store the camera stream
  const [capturedImage, setCapturedImage] = useState(null);  // State to store the captured image URL

  useEffect(() => {
    const startCamera = async () => {
      try {
        console.log("Requesting camera access...");
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        console.log("Camera stream initialized successfully.");
        streamRef.current = stream;  // Store the stream reference
        if (videoRef.current) {
          videoRef.current.srcObject = stream;  // Attach the stream to the video element
        }
      } catch (err) {
        console.error("Error accessing the camera:", err);
      }
    };

    startCamera();

    // Cleanup the camera stream when the component unmounts
    return () => {
      if (streamRef.current) {
        const tracks = streamRef.current.getTracks();
        tracks.forEach(track => track.stop()); // Stop all tracks of the stream
      }
    };
  }, []);

  // Capture image from the video stream and draw it on the canvas
  const captureImage = async() => {
    const video = videoRef.current;
    const canvas = canvasRef.current;

    // Ensure canvas size matches video dimensions
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    const context = canvas.getContext('2d');
    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    // Convert the canvas to an image URL (data URL)
    const imageURL = canvas.toDataURL('image/png');
    setCapturedImage(imageURL);  // Store the captured image URL in the state

    let newMessage = await getDataCarInsurance("preparinginsurancepolicy");
    console.log(newMessage);
    
    setMessages((prevMessages) => [...prevMessages, newMessage.modal]);

    if (newMessage.modal.options && newMessage.modal.options.length > 0) {
      newMessage.modal.options.forEach((option) => {
        option.isOption = true;
        option.sender = "Receiver";
        setMessages((prevMessages) => [...prevMessages, option]);
      });
    }
    setShowCamera(false);
  };

  return (
    <div>
      {/* Display video feed */}
      <video
        ref={videoRef}
        autoPlay
        playsInline
        style={{ backgroundColor: '#000', border: '1px solid #ccc', width: '100%', height: '80vh' }}
      />

      {/* Button to capture the image */}
      <br />
      <button onClick={captureImage}>Capture</button>

      {/* Display the captured image */}
      {capturedImage && (
        <div>
          <h3>Captured Image:</h3>
          <img src={capturedImage} alt="Captured" style={{ width: '100%', height: 'auto' }} />
        </div>
      )}

      {/* Canvas is used to capture the image but is hidden */}
      <canvas ref={canvasRef} style={{ display: 'none' }} />
    </div>
  );
};

export default CameraComponent;
