class thirdparty {
  constructor() {
    this.items = {
      id: 30001,
      name: "Third Party Only",
      type: "text",
      slideimg: "/doctorassistant/dia.jpg",
      options: [
        {
          id: 40012,
          text: "Toyota",
          name: "Toyota",
          price: "",
          type: "vehiclemake",
          desc: "Toyota",
          inputtype: "button",
        },
        {
          id: 40012,
          text: "Honda",
          name: "Honda",
          price: "",
          type: "vehiclemake",
          desc: "Honda",
          inputtype: "button",
        },
        {
          id: 40011,
          text: "Suzuki",
          name: "Suzuki",
          price: "",
          type: "vehiclemake",
          desc: "Suzuki",
          inputtype: "button",
        },
      ],
      backtostart: false,
      text: [
        `<p>Third-party car insurance is the most basic type of coverage. It only covers damages or losses caused to a third party if you're at fault in an accident. It doesn't provide any coverage for damages to your own vehicle.</p> `,
        `<p>Perfect. You love your vehicle. It must be very costly. Let’s get some more information. Please select the make of your vehicle</p> `,
      ],
    };
  }

  getAllItems() {
    return this.items;
  }
}

export default thirdparty;
