class depreciationmessage {
    constructor(data) {
        const price = data.price;
        this.items = {
            id: 30001,
            name: "Depreciation Message",
            type: "text",
            slideimg: "/doctorassistant/dia.jpg",
            options: [
                { id: 40012, text: "Yes, I need GAP insurance", name: "Yes, I need GAP insurance", price: `${price}`, type: "gapinsurance", desc: "Yes, I need GAP Insurance", inputtype: "button" },
                { id: 40012, text: "No, I do not need GAP insurance", name: "No, I do not need GAP insurance", price: `${price}`, type: "gapinsurance", desc: "No, I do not need GAP insurance", inputtype: "button" },
            ],
            backtostart: false,
            text: [
                `<p>We are nearly there. Do you know that you have to pay the depreciation if your vehicle is damaged and you require parts? You can cover the depreciation by purchasing Value addition service.</p>`,
                `<p>How does this sound?</p>`
            ]
        };
    }

    getAllItems() {
        return this.items;
    }
}

export default depreciationmessage;
