import comprehensive from "./comprehensive";
import start from "./start";
import thirdpartytheft from "./thirdpartytheft";
import thirdparty from "./thirdparty";
import vehiclemake from "./vehiclemake";
import vehiclemodel from "./vehiclemodel";
import vehicleyear from "./vehicleyear";
import vehicleprice from "./vehicleprice";
import confirmprice from "./confirmprice";
import additionalinfo from "./additionalinfo";
import depreciationmessage from "./depreciationmessage";
import gapinsurance from "./gapinsurance";
import promotions from "./promotions";
import promotionsstep1 from "./promotionsstep1";
import promotionsstep2 from "./promotionsstep2";
import paymentoptions from "./paymentoptions";
import customvehiclemake from "./customvehiclemake";
import customcomperhensive from "./customcomperhensive";
import additionalcover from "./additionalcover";
import sendtosurveyor from "./sendtosurveyor";
import allowcamera from "./allowcamera";
import preparinginsurancepolicy from "./preparinginsurancepolicy";

const getDataCarInsurance = async (type, msg) => {
    let newMessage;
    if (type === 'start') {
        newMessage = new start();
    }
    else if (type === 'comprehensive') {
        newMessage = new comprehensive();
    }
    else if (type === 'thirdpartytheft') {
        newMessage = new thirdpartytheft();
    }
    else if (type === 'thirdparty') {
        newMessage = new thirdparty();
    }
    else if (type === 'vehiclemake') {
        newMessage = new vehiclemake(msg);
    }
    else if (type === 'vehiclemodel') {
        newMessage = new vehiclemodel(msg);
    }
    else if (type === 'vehicleyear') {
        newMessage = new vehicleyear(msg);
    }
    else if (type === 'vehicleprice') {
        newMessage = new vehicleprice();
    }
    else if (type === 'confirmprice') {
        newMessage = new confirmprice(msg);
    }
    else if (type === 'depreciationmessage') {
        newMessage = new depreciationmessage(msg);
    }
    else if (type === 'gapinsurance') {
        newMessage = new gapinsurance(msg);
    }
    else if (type === 'promotions') {
        newMessage = new promotions(msg);
    }
    else if (type === 'promotionsstep1') {
        newMessage = new promotionsstep1(msg);
    }
    else if (type === 'promotionsstep2') {
        newMessage = new promotionsstep2(msg);
    }
    else if (type === 'paymentoptions') {
        newMessage = new paymentoptions(msg);
    }
    else if (type === 'additionalinfo') {
        newMessage = new additionalinfo();
    }
    else if (type === 'customcomperhensive') {
        newMessage = new customcomperhensive(msg);
    }
    else if (type === 'customvehiclemake') {
        newMessage = new customvehiclemake(msg);
    }
    else if (type === 'additionalcover') {
        newMessage = new additionalcover(msg);
    }
    else if (type === 'sendtosurveyor') {
        newMessage = new sendtosurveyor(msg);
    }
    else if (type === 'allowcamera') {
        newMessage = new allowcamera(msg);
    }
    else if (type === 'preparinginsurancepolicy') {
        newMessage = new preparinginsurancepolicy(msg);
    }

    return {
        modal: await newMessage.getAllItems()
    };
};

export default getDataCarInsurance;