import React, { useState } from 'react';
import { Form, Button, InputGroup } from 'react-bootstrap';

const TextInputOption = ({ msg, handleTextSubmit }) => {
    const [text, setText] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        handleTextSubmit(msg,text);
        setText('');
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3 mx-2">
                <InputGroup>
                    <Form.Control
                        type="text"
                        placeholder="Enter value"
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        required
                    />
                    <Button variant="secondary"
                            style={{ backgroundColor: "#1bb367" }}
                            className="border-0 chatOptions"
                            type="submit">
                        Submit
                    </Button>
                </InputGroup>
            </Form.Group>
        </Form>
    );
};

export default TextInputOption;
