class faqs {
  constructor() {
    this.items = {
      id: 30002,
      name: "FAQ",
      type: "text", slideimg: "/doctorassistant/dia.jpg",
      options: [
        { id: 40012,text:"Connect to the Doctor", name: "Connect to the Doctor", price: "", type: "doctor", desc: "I’d like to connect with a doctor." },
        { id: 40012,text:"About us", name: "About us", price: "", type: "about", desc: "About us"},
        
      ],
      backtostart: true,
      text: `<ol>
<li><strong> What is WEBDOC? </strong></li>
</ol>
<p>WEBDOC is a digital platform that connects patients with licensed in-house doctors and healthcare providers for consultations, prescriptions, and medical advice from the comfort of your home.</p>
<ol start="2">
<li><strong> What services are available on the app? </strong></li>
</ol>
<p>Services include audio &amp; video doctor consultations and prescription management.</p>
<ol start="3">
<li><strong> Can I consult for emergencies on this app?</strong></li>
</ol>
<p>This app is not suitable for life-threatening emergencies. Please visit your nearest emergency center in such cases.</p>
<ol start="4">
<li><strong> Are the doctors licensed professionals? </strong></li>
</ol>
<p>Yes, all doctors on the platform are PMDC certified, and experienced professionals.</p>
<ol start="5">
<li><strong> Can I consult a doctor 24/7? </strong></li>
</ol>
<p>No, WEBDOC offers access to doctors 9 am &ndash; 9 pm.</p>
<ol start="6">
<li><strong> Will the doctor prescribe medications during the consultation? </strong></li>
</ol>
<p>Yes, if necessary, the doctor can provide a digital prescription through the app.</p>
<ol start="7">
<li><strong> Is my health information secure? </strong></li>
</ol>
<p>Absolutely. WEBDOC uses advanced encryption to ensure that your personal and health data is kept confidential.</p>
<ol start="8">
<li><strong> Can I share my medical records with the doctor? </strong></li>
</ol>
<p>Yes, you can upload and share medical records, test results, and prescriptions securely during your consultation.</p>
<ol start="9">
<li><strong> Can I get prescriptions through the app? </strong></li>
</ol>
<p>Yes, doctors can provide digital prescriptions, which are accessible through the app after your consultation.</p>
<ol start="10">
<li><strong> Can I access my past medical history on the app?</strong></li>
</ol>
<p>&nbsp;Yes, all your consultation records, prescriptions, are saved and accessible in your account under &ldquo;Prescriptions&rdquo;.</p>
<ol start="11">
<li><strong> Who can use this app? </strong></li>
</ol>
<p>The app is designed for anyone seeking accessible and reliable healthcare services, whether for routine checkups, follow-ups, or urgent medical advice.</p>
<ol start="12">
<li><strong> How long does an online consultation last? </strong></li>
</ol>
<p>Typically, consultations last around 15&ndash;20 minutes, but the duration may vary based on your medical needs.</p>`,
    };
  }

  getAllItems() {
    return this.items;
  }
}

export default faqs;
