import { toWords } from 'number-to-words';
class confirmprice {
  constructor(data) {
    const price = data.price;
    const marketValue = parseFloat(price);
    const insuranceCost = marketValue * 0.013;
    const formattedInsuranceCost = insuranceCost.toLocaleString("en-US");
    const insuranceCostInWords = toWords(insuranceCost);
    this.items = {
      id: 30001,
      name: "Confirm Price",
      type: "text",
      slideimg: "/doctorassistant/dia.jpg",
      options: [
        {
          id: 40012,
          text: "Confirm",
          name: "Confirm",
          price: `${price}`,
          type: "depreciationmessage",
          desc: "Confirm",
          inputtype: "button",
        },
        {
          id: 40012,
          text: "Change Value",
          name: "Change Value",
          price: "",
          type: "vehicleprice",
          desc: "Change Value",
          inputtype: "button",
        },
      ],
      backtostart: false,
      text: [
        `<p>Based on the information provided, the premium is Rs. ${formattedInsuranceCost} (${insuranceCostInWords}).</p> `,
      ],
    };
  }

  getAllItems() {
    return this.items;
  }
}

export default confirmprice;
