// src/components/ActivatedPackageComponent.js
import React, { useState } from "react";
import Swal from "sweetalert2";
import "../../src/style.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useLoading } from "../contexts/LoadingContext";
import { BsDatabaseCheck } from "react-icons/bs";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import confetti from "canvas-confetti";

const PocketSizeActivatedPackageComponent = ({ items }) => {
  const { isLoading, setIsLoading } = useLoading();
  // console.log(items)
  const [transactions, setTransactions] = useState([]);

  const userData = JSON.parse(sessionStorage.getItem("user"));
  const number = userData?.userMsisdn || null;
  const navigate = useNavigate();
  const location = useLocation();

  const redirectFromPaymentSuccess = sessionStorage.getItem(
    "redirectFromPaymentSuccess"
  );
  if (redirectFromPaymentSuccess === "true") {
    Swal.fire({
      icon: "info",
      title: "Confirmation",
      text: "Your package has been activated successfully.",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
    }).then(() => {
      // Clear the flag from sessionStorage
      sessionStorage.removeItem("redirectFromPaymentSuccess");
    });
  }

  const handleUnsubscribe = (pidsub) => {
    Swal.fire({
      title: "Chance to Win",
      text: "Stay with us, and you could WIN Rs. 10,000 in CASH! T&C apply. Don't miss out!",
      imageUrl: "/assets/MiniAppAssets/reward.jpg",
      imageWidth: 200,
      imageHeight: 150,
      imageAlt: "Reward Image",
      showCancelButton: true,
      showDenyButton: true,
      confirmButtonText: "Unsub Anyway",
      denyButtonText: "Keep me Subscribed",
      cancelButtonText: "Cancel",
      allowOutsideClick: false,
      customClass: {
        confirmButton: "btn btn-danger mx-2",
        denyButton: "btn btn-success mx-2",
        cancelButton: "btn btn-info mx-2 mt-2",
      },
      buttonsStyling: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Are you sure?",
          text: "Do you really want to unsubscribe?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, unsubscribe",
          cancelButtonText: "Cancel",
          customClass: {
            confirmButton: "btn btn-danger mx-2",
            cancelButton: "btn btn-info mx-2",
          },
          buttonsStyling: false,
        }).then(async (confirmResult) => {
          if (confirmResult.isConfirmed) {
            const user = JSON.parse(sessionStorage.getItem("user"));
            const OpenId = user.openId;
            const pid = pidsub;
            const service = "Insurance";
            const number = user.userMsisdn || null;

            try {
              const response = await fetch(
                `https://insuranceservice.webddocsystems.com/api/MiniApp/UnsubServices`,
                {
                  method: "POST",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization:
                      "Basic ZlJqZGZVbWFzOmFmMzNhZmNhODAwNzZkOWMxMTViOGU0NjAyZjc4YTIx",
                  },
                  body: JSON.stringify({
                    OpenId,
                    service,
                    pid,
                    number,
                  }),
                }
              );

              if (!response.ok) {
                throw new Error("Network response was not ok");
              }

              const data = await response.json();
              console.log(data.responseCode);
              if (data.responseCode === "0000") {
                // Remove the package from the active subscriptions
                const updatedTransactions = transactions.filter(
                  (t) => t.activePackage.productId !== pid
                );

                // Update the state to reflect the changes
                setTransactions(updatedTransactions);

                Swal.fire({
                  title: "Unsubscribed!",
                  text: "You have successfully unsubscribed from the service.",
                  icon: "success",
                  confirmButtonText: "OK",
                  allowOutsideClick: false,
                }).then(() => {
                  setIsLoading(true);
                  window.location.reload();
                  //navigate("/income")
                });
              } else {
                Swal.fire({
                  title: "Error!",
                  text: data.message,
                  icon: "error",
                  confirmButtonText: "OK",
                  allowOutsideClick: false,
                });
              }
            } catch (error) {
              console.error("Error unsubscribing:", error);
              Swal.fire({
                title: "Error!",
                text: "There was an error unsubscribing from the service. Please try again later.",
                icon: "error",
                confirmButtonText: "OK",
                allowOutsideClick: false,
              });
            }
          }
        });
      } else if (result.isDenied) {
        Swal.fire({
          title: "Thank You!",
          text: "You have chosen to stay subscribed. Good luck!",
          icon: "success",
          confirmButtonText: "OK",
          allowOutsideClick: false,
        }).then(() => {
          confetti({
            particleCount: 300,
            spread: 100,
            origin: { y: 0.6 },
          });
        });
      }
    });
  };
  // console.log(items);

  return (
    <div className="container-fluid">
      <div className="container">
        {Array.isArray(items) && items.length > 0 ? (
          <Carousel
            showThumbs={false}
            showStatus={false}
            infiniteLoop
            useKeyboardArrows
            showIndicators={false}
            autoPlay={false}
          >
            {items.map((item, index) => (
              <div key={index} className="row mt-2 position-relative mx-1">
                <div className="card selected_package border-2">
                  <div className="card-header p-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                          <BsDatabaseCheck fontSize={35} />
                        </div>
                        <div className=" ms-2 d-flex flex-column text-start">
                          <span
                            className="fw-bold mb-0"
                            style={{ fontSize: "15px" }}
                          >
                            {item.pkgPrice}
                          </span>
                          <span
                            className="fw-light text-secondary"
                            style={{ fontSize: "10px" }}
                          >
                            {item.pName}
                          </span>
                        </div>
                      </div>
                      <div className="mb-0 text-end d-flex flex-column">
                        <span
                          className="badge mb-1 mt-1 fw-light fs-6 rounded-pill"
                          style={{ backgroundColor: "#1bb367" }}
                        >
                          Subscribed
                        </span>
                        <span
                          className="badge mb-1 mt-1 fw-light fs-6 rounded-pill"
                          style={{ backgroundColor: "#f44336" }}
                          onClick={() => handleUnsubscribe(item.pid)}
                        >
                          Unsub
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="card-body text-start">
                    <p className="mb-1 fs-12">
                      Active Date:{" "}
                      <span
                        className="fw-light text-secondary"
                        style={{ fontSize: "12px" }}
                      >
                        {item.activateDate}
                      </span>
                    </p>
                    <p className="mb-1 fs-12">
                      Expire Date:{" "}
                      <span
                        className="fw-light text-secondary"
                        style={{ fontSize: "12px" }}
                      >
                        {item.expiryDate}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
        ) : (
          <p></p>
        )}
      </div>
    </div>
  );
};

export default PocketSizeActivatedPackageComponent;
