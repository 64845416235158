class sendtosurveyor {
  constructor() {
    this.items = {
      id: 30001,
      name: "Personal Info",
      type: "text",
      slideimg: "/doctorassistant/dia.jpg",
      options: [
      ],
      backtostart: false,
      text: [
        `<p>We will connect you once supervisor is available. Drive safe</p> `,
      ],
    };
  }

  getAllItems() {
    return this.items;
  }
}

export default sendtosurveyor;
