class comprehensive {
  constructor() {
    this.items = {
      id: 30001,
      name: "Comprehensive",
      type: "text",
      slideimg: "/doctorassistant/dia.jpg",
      options: [
        {
          id: 40012,
          text: "Toyota",
          name: "Toyota",
          type: "vehiclemake",
          desc: "Toyota",
          inputtype: "button",
        },
        {
          id: 40012,
          text: "Honda",
          name: "Honda",
          type: "vehiclemake",
          desc: "Honda",
          inputtype: "button",
        },
        {
          id: 40011,
          text: "Suzuki",
          name: "Suzuki",
          type: "vehiclemake",
          desc: "Suzuki",
          inputtype: "button",
        },
        {
          id: 40011,
          text: "Other",
          name: "Other",
          type: "vehiclemake",
          desc: "Other",
          inputtype: "textinput",
        },
      ],
      backtostart: false,
      text: [
        `<p>It is a type of car insurance that provides extensive coverage, protecting both the policyholder’s vehicle and third parties. It covers damages caused by accidents, theft, natural disasters, vandalism, and other unforeseen incidents. This is high end insurance policy</p> `,
        `<p>Perfect. You love your vehicle. It must be very costly. Let’s get some more information. Please select the make of your vehicle</p> `,
      ],
    };
  }

  getAllItems() {
    return this.items;
  }
}

export default comprehensive;
