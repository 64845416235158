import Header_packages from "../components/Header_packages";
import ChatDoctorList from "../components/ChatDoctorList";
import { Container } from "react-bootstrap";
import { useState } from "react";
import { Button } from "react-bootstrap";

const ChatDoctorsListPage = () => {
  const [showList, setShowList] = useState(false);

  return (
    <>
      <Header_packages title="Doctors" />
      <Container className="mt-2 pt-3">
        <Button onClick={() => setShowList(true)}>Load Doctors List</Button>
        {showList && <ChatDoctorList />}
      </Container>
    </>
  );
};

export default ChatDoctorsListPage;
