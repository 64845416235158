class vehiclemodel {
    constructor(msg) {
        const sessionUser = JSON.parse(sessionStorage.getItem("user"));
        this.items =
        {
            id: 30001,
            name: "Vehicle Model",
            type: "text",
            slideimg: "/doctorassistant/dia.jpg",
            options: [
                { id: 40012,text:"2015", name: "2015", price: "2015", type: "vehicleprice", desc: "2015", inputtype:"button" },
                { id: 40012,text:"2016", name: "2016", price: "2016", type: "vehicleprice", desc: "2016", inputtype:"button" },
                { id: 40012,text:"2017", name: "2017", price: "2017", type: "vehicleprice", desc: "2017", inputtype:"button" },
                { id: 40012,text:"2018", name: "2018", price: "2018", type: "vehicleprice", desc: "2018", inputtype:"button" },
                { id: 40012,text:"2019", name: "2019", price: "2019", type: "vehicleprice", desc: "2019", inputtype:"button" },
                { id: 40012,text:"2020", name: "2020", price: "2020", type: "vehicleprice", desc: "2020", inputtype:"button" },
                { id: 40012,text:"2021", name: "2021", price: "2021", type: "vehicleprice", desc: "2021", inputtype:"button" },
                { id: 40012,text:"2022", name: "2022", price: "2022", type: "vehicleprice", desc: "2022", inputtype:"button" },
                { id: 40012,text:"2023", name: "2023", price: "2023", type: "vehicleprice", desc: "2023", inputtype:"button" },
                { id: 40012,text:"2024", name: "2024", price: "2024", type: "vehicleprice", desc: "2024", inputtype:"button" },
                { id: 40012,text:"2025", name: "2025", price: "2025", type: "vehicleprice", desc: "2025", inputtype:"button" },
                ],
            backtostart: false,
            text:[
                `<p>Excellent! What year was your ${msg.desc} manufactured?</p> `
            ]
        }
    }

    getAllItems() {
        return this.items;
    }
}

export default vehiclemodel;
