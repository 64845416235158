import React, { useState } from "react";

const HandleMessage = ({ messages, startagain }) => {
  const [expandedMessages, setExpandedMessages] = useState({});

  const handleReadMoreClick = (index) => {
    setExpandedMessages((prevState) => ({
      ...prevState,
      [index]: !prevState[index], // Toggle the expanded state for this specific message
    }));
  };

  return (
    <>
      {messages.map((msg, index) => (
        <div key={index}>
          {/* this is main image */}
          {msg.img && msg.img !== "" && (
            <div className="row mt-2">
              <div className="col-12 text-center">
                <img
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "cover",
                  }}
                  className="rounded-circle img-fluid"
                  src={msg.img}
                  alt=""
                />
                <br />
                <strong className="mt-2">{msg.display}</strong>
                <br />
                <small>{msg.position}</small>
              </div>
            </div>
          )}

          <div className="d-flex">
            {/* this is side image on all chats */}
            {!msg.isOption && msg.sender !== "You" && (
              <div className="flex-shrink-0 mt-2">
                <img
                  src={msg.slideimg}
                  alt="customer executive"
                  style={{
                    width: "25px",
                    height: "30px",
                    objectFit: "cover",
                  }}
                  className="rounded-circle img-fluid"
                />
              </div>
            )}

            {/* this is chat started */}
            <div
              style={{ minWidth: "30%" }}
              className={
                msg.sender !== "You"
                  ? "flex-grow-1 ms-2"
                  : "ms-auto justify-content-end"
              }
            >
              {msg.text &&
                Array.isArray(msg.text) &&
                msg.text.length > 0 &&
                !msg.isOption && (
                  <>
                    {msg.text.map((paragraph, idx) => (
                      <div
                        key={idx}
                        className={`message shadow-sm ${
                          msg.sender === "You" ? "sender" : "receiver"
                        } ${idx !== 0 ? "mt-2" : ""}`} // Add mt-2 if idx is not 0
                      >
                        <div className="message_text">
                          {msg.sender !== "Receiver" && (
                            <div className="message_sender"></div>
                          )}
                          <div
                            className={`message-content ${
                              expandedMessages[index] ? "expanded" : ""
                            }`}
                            dangerouslySetInnerHTML={{ __html: paragraph }}
                          ></div>
                        </div>

                        {/* Read more button */}
                        {msg.text.join("").length > 210 &&
                          !expandedMessages[index] && (
                            <>
                              <a
                                href="#"
                                className="text-decoration-none read-more-btn"
                                onClick={() => handleReadMoreClick(index)}
                              >
                                Read More
                              </a>
                              <br />
                            </>
                          )}
                      </div>
                    ))}
                    {msg.backtostart && (
                      <a
                        href
                        className="text-decoration-none"
                        style={{ cursor: "pointer" }}
                        onClick={() => startagain("start")}
                      >
                        Start Again
                      </a>
                    )}
                  </>
                )}
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default HandleMessage;
