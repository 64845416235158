
class precription {
    constructor() {
        this.items = {
            id: 30003,
            name: "Prescription Click",
            type: "text",
            options: [
                { id: 71100, text: "Excellent", name: "Excellent",  type: "feedbackExe", desc: "Excellent" },
                { id: 71101, text: "Good", name: "Good",  type: "feedbackGood", desc: "Good" },
                { id: 71102, text: "Bad", name: "Bad",  type: "feedbackBad", desc: "Bad" },
            ],
            backtostart: true,
            text: ``,
            slideimg: "/doctorassistant/natasha.jpg",
            issuccess:false
        };
    }

    // Method to make the API call, now accepting customerNumber as a parameter
    async getAllItems() {
        this.items.text =`Your valuable feedback will play a key role in enhancing our services and ensuring we continue to exceed your expectations. Please rate the service`;
        return this.items;
   
        // };
    }
}
export default precription;
