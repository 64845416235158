class paymentoptions {
    constructor() {
        this.items = {
            id: 30001,
            name: "Payment Options",
            type: "text",
            slideimg: "/doctorassistant/dia.jpg",
            options: [
                { id: 40012, text: "Pay Now in Full", name: "Pay Now in Full", price: "", type: "additionalinfo", desc: "Pay Now in Full", inputtype: "button" },
                { id: 40012, text: "Pay by Installments", name: "Pay by Installments", price: "", type: "additionalinfo", desc: "Pay by Installments", inputtype: "button" },
            ],
            backtostart: false,
            text: [
                `<p>Please choose your payment option:</p>`
            ]
        };
    }

    getAllItems() {
        return this.items;
    }
}

export default paymentoptions;
