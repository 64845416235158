import React, { useState, useEffect, useRef } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import Header_packages_chat from "../components/Header_package_chat";

import ChatLoader from "../components/ChatLoader";
import HandleMessage from "../components/carinsurance/HandleMessage";
import HandleOptions from "../components/carinsurance/HandleOptions";
import CameraComponent from "../components/carinsurance/camera";
import getDataCarInsurance from "../modals/carinsurance/getcarinsurancemodel";
import TextInputOption from "../components/carinsurance/TextInputOption";

const CarInsuranceChat = () => {
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [showCamera, setShowCamera] = useState(false);
  const [sideimage, setSideImage] = useState("/doctorassistant/dia.jpg");
  useEffect(() => {
    let item = {
      id: 1,
      name: "Start Text",
      type: "text",
      options: [
        {
          id: 40012,
          text: "Connect to the Doctor",
          name: "Connect to the Doctor",
          price: "",
          type: "doctor",
          desc: "I’d like to connect with a doctor.",
        },
        {
          id: 40012,
          text: "About us",
          name: "About us",
          price: "",
          type: "about",
          desc: "About us",
        },
        {
          id: 40011,
          text: "FAQs",
          name: "FAQ",
          price: "",
          type: "faqs",
          desc: "FAQ",
        },
      ],
      backtostart: false,
      img: "/doctorassistant/dia.jpg",
      slideimg: "/doctorassistant/dia.jpg",
      display: "Nida",
      position: "Customer Executive",
      text: [
        `<p>Hi, I am Nida, the car insurance expert. Vehicles are really getting expensive and is a very important asset for anyone. Car insurance is a financial protection policy that covers you against losses or damages related to your vehicle. It helps pay for repairs, medical expenses, or legal liabilities in case of accidents, theft, or other unforeseen events</p>`,
      ],
    };

    setMessages((prevMessages) => [...prevMessages, item]);
    // setIsLoading(true)
    setLoading(true);
    const sessionUser = JSON.parse(sessionStorage.getItem("user"));
    if (sessionUser) {
      setTimeout(async () => {
        await loadData("start", null);
      }, 2000);
    } else {
      //authenticateUser(); // Uncomment if you want to initiate authentication
    }
  }, []);
  const startagain = async (type) => {
    let item = {
      id: 1,
      name: "Start Text",
      type: "text",
      options: [
        {
          id: 40012,
          text: "Connect to the Doctor",
          name: "Connect to the Doctor",
          price: "",
          type: "doctor",
          desc: "I’d like to connect with a doctor.",
        },
        {
          id: 40012,
          text: "About us",
          name: "About us",
          price: "",
          type: "about",
          desc: "About us",
        },
        {
          id: 40011,
          text: "FAQs",
          name: "FAQ",
          price: "",
          type: "faqs",
          desc: "FAQ",
        },
      ],
      backtostart: false,
      slideimg: "",
      img: "/doctorassistant/dia.jpg",
      slideimg: "/doctorassistant/dia.jpg",
      display: "Diya",
      position: "Customer Executive",
      text: [`<p>Good morning! I&rsquo;m Diya, your virtual health assistant at <strong>WEB<span style='color: #ff0000;'>DOC</span></strong>. Give me just a moment while I fetch your details, this won&rsquo;t take long!</p>`],
    };

    setMessages([]); // Clears all messages
    setMessages((prevMessages) => [...prevMessages, item]);
    await loadData(type, null);
  };
  const loadData = async (type, data) => {
    //alert(diseasename)
    const newMessage = await getDataCarInsurance(type, data);
    console.log(newMessage.modal);
    setLoading(false);

    setMessages((prevMessages) => [...prevMessages, newMessage.modal]);

    if (newMessage.modal.options && newMessage.modal.options.length > 0) {
      newMessage.modal.options.forEach((option) => {
        option.isOption = true;
        option.sender = "Receiver";
        setMessages((prevMessages) => [...prevMessages, option]);
      });
    }
    console.log(messages);
  };

  const handleOptionSelect = async (msg) => {
    setLoading(true); // Show loader first

    console.log(msg.desc);
    setMessages((prevMessages) => prevMessages.filter((msg) => !msg.isOption));
    if(msg.type === "opencamera") {
        setShowCamera(true);
        setLoading(false);
    }
    else
    {
    // Remove all option messages before doing anything
       
        setMessages((prevMessages) => [
            ...prevMessages,
            { sender: "You", text: [msg.desc] },
        ]);

        setTimeout(() => {
        loadData(msg.type, msg);
        }, 2000); // 2 second delay
    }
  };

  const handleTextSubmit = async (msg, text) => {
    setLoading(true);
    console.log(text);
    setMessages((prevMessages) => prevMessages.filter((msg) => !msg.isOption));
    setMessages((prevMessages) => [
      ...prevMessages,
      { sender: "You", text: [text] },
    ]);
    msg.desc=text;
    if(msg.type === "confirmprice") {
      msg.price=text;
    }


    setTimeout(() => {
      loadData(msg.type === "vehicleprice" ? "confirmprice" : msg.type, msg);
    }, 2000);
  };
  return (
    <>
      <Header_packages_chat title="Chat" />
      <Container>
        <Row>
          <Col className="chat_container" md={12}>
            <div className="chat_window pt-2">
              <div className="messages">
                <HandleMessage messages={messages} startagain={startagain} />
                <>
                  <HandleOptions
                    messages={messages}
                    handleOptionSelect={handleOptionSelect}
                    handleTextSubmit={handleTextSubmit}
                  />
                </>
                {showCamera && (
                <CameraComponent
                  messages={messages}
                  setMessages={setMessages}
                  setShowCamera={setShowCamera}
                  setLoading={setLoading}
                />
              )}
                {loading && <ChatLoader />}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CarInsuranceChat;
