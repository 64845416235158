class additionalinfo {
  constructor() {
    this.items = {
      id: 30001,
      name: "Personal Info",
      type: "text",
      slideimg: "/doctorassistant/dia.jpg",
      options: [
        {
          id: 40012,
          text: "Car Registration Number",
          name: "Car Registration Number",
          price: "",
          type: "additionalcover",
          desc: "Car Registration Number",
          inputtype: "button",
        },
        {
          id: 40012,
          text: "Car chassis/Engine Number",
          name: "Car chassis/Engine Number",
          price: "",
          type: "additionalcover",
          desc: "Car chassis/Engine Number",
          inputtype: "button",
        },
      ],
      backtostart: false,
      text: [
        `<p>Lovley option .. Payment successful.</p> `,
        `<p>Let us get more informaon to cover you asap</p> `,
        `<p>Please select what you have handy</p> `,
      ],
    };
  }

  getAllItems() {
    return this.items;
  }
}

export default additionalinfo;
