class additionalcover {
  constructor() {
    this.items = {
      id: 30001,
      name: "Additional Cover",
      type: "text",
      slideimg: "/doctorassistant/dia.jpg",
      options: [
        {
          id: 40012,
          text: "Yes, I can do it now",
          name: "Yes, I can do it now",
          price: "",
          type: "allowcamera",
          desc: "Yes, I can do it now",
          inputtype: "button",
        },
        {
          id: 40012,
          text: "Send a surveyor",
          name: "Send a surveyor",
          price: "",
          type: "sendtosurveyor",
          desc: "Send a surveyor",
          inputtype: "button",
        },
      ],
      backtostart: false,
      text: [
        `<p>Please take the Vehicles Pictures. The survey is mandatory by law. You can do
it here or we can send someone to take pictures for free – This is only for
comprehensive insurance , for other 2 , please provide camera`,
        `<p>Do you want to get it?</p> `,
      ],
    };
  }

  getAllItems() {
    return this.items;
  }
}

export default additionalcover;
