class vehicleyear {
  constructor(msg) {
    this.items = {
      id: 30001,
      name: "Vehicle Year",
      type: "text",
      slideimg: "/doctorassistant/dia.jpg",
      options: [
        {
          id: 40012,
          text: "Additional Cover",
          name: "Additional Cover",
          price: "",
          type: "additionalcover",
          desc: "Additional Cover",
          inputtype: "button",
        },
      ],
      backtostart: false,
      text: [
        `<p>Okay, great! Just one more thing, do you need additional cover?</p> `,
      ],
    };
  }

  getAllItems() {
    return this.items;
  }
}

export default vehicleyear;
